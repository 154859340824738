.login-container {
  display: flex;
  min-height: 100vh;
}

.login.sidebar {
  width: 40%;
  background: #f7f8fa;
}

.desktop-view-container img {
  height: 35px;
}

// mobile style
.mobile-view-login {
  width: 100%;
  display: none;
}

.intro-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 44px;
  color: #ffffff;
  display: flex;
}
.intro-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #667985;
}

.sidebar-content {
  padding: 50px 50px;
}
.sidebar-title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #3e4a59;
  margin-top: 40px;
}
.mobile-view-login.login.right-box-content {
  flex: 1;
  background: #ffffff;
  display: flex;
  align-items: inherit;
  flex-direction: column;
  justify-content: unset !important;
  height: 100vh;
}
.login.right-box-content {
  flex: 1;
  background: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .lh-login-form {
    border-radius: 8px;
    padding: 25px 15px;
    width: 70%;
    margin-top: 40px;
    .login-title {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 24px;
      color: #3e4a59;
    }
  }
  .hr-line {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #e6eff0;
    line-height: 0.1em;
    margin: 45px 0 25px;
  }

  .hr-line span {
    background: #fff;
    padding: 0 10px;
    color: #667985;
  }
  .lh-login-form label {
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: #3d4144;
    display: flex;
    margin-bottom: 15px;
  }
  .login-input {
    background: #f1f3f5;
    border-radius: 10px;
    border: 1px solid #eaecf1;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.07);
    height: 45px;
    width: 100%;
    padding: 15px 20px;
    margin-top: 25px;
    outline: none;
  }
  .login-input.border-red {
    border: 1px solid #cb3c3a;
  }

  .lh-login-submit-button {
    background: #1bb4a4;
    box-shadow: 1px 2px 7px rgba(13, 109, 94, 0.2);
    border-radius: 64px !important;
    border-color: #1bb4a4;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #fff;
    flex: none;
    order: 0;
    align-self: center;
    margin-top: 20px;
    cursor: pointer;
    outline: none;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  .lh-login-button {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(52, 143, 108, 0.3);
    font-weight: normal;
    border-radius: 0px;
    font-size: 14px;
    line-height: 14px;
    color: #3e4a59;
    flex: none;
    order: 0;
    align-self: center;
    margin-top: 20px;
    cursor: pointer;
    outline: none;
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  .error-login-button {
    border-color: #cb3c3a;
    border-width: 1px;
  }
  .button-error-text {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #cb3c3a;
    margin-top: 10px;
  }
  .tranparent-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #1bb4a4;
    background: transparent;
    box-shadow: unset;
    border: 0;
  }
  .googleImage {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }
  .lh-login-submit-button:active {
    transform: scale(0.98);
    /* Scaling button to 0.98 to its original size */
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    /* Lowering the shadow */
  }
  .lh-login-submit-button.disabled {
    background: #348f6cbd;
    border: 1px solid #348f6cbd;
  }
}

.login-bg-side-container {
  background-color: #e6eff0;
  border-radius: 10px;
  margin-top: 47px;
}

.lh-login-bottom-image {
  margin-top: -47px;
}

.login .verify-heading {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: #3e4a59;
}
.login .verify-body {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #667985;
}

.login .verify-email {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #3e4a59;
}

.login .invalid-feedback {
  color: red;
  padding: 10px 5px;
  font-size: 12px;
  line-height: 12px;
}
.error-message {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #cb3c3a;
  margin-top: 10px;
}
::ng-deep #spinner {
  border: 2px solid #fff !important;
  width: 18px !important;
  height: 18px !important;
  border-top-color: #348f6c !important;
}

.mobile-view-container {
  display: none;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .login-container {
    min-height: 100%;
  }
  .login.sidebar {
    display: none;
  }
  .login.right-box-content {
    align-items: self-start;
  }
  .login.right-box-content img {
    // margin-bottom: 15px;
    margin-top: 0px;
  }
  .login.right-box-content .lh-login-form {
    width: 100%;
  }
  .mobile-view-container {
    display: block;
  }

  .mobile-view-login {
    display: block;
  }
  .xs-hidden {
    display: none;
  }
  .login.right-box-content .lh-login-form {
    margin-top: 20px;
  }
  .login.right-box-content .login-input {
    margin-top: 5px;
  }
}

.desktop-view-container {
  display: none;
  margin-top: 20px;
}

@media only screen and (min-device-width: 568px) {
  .desktop-view-container {
    display: block;
  }
}

.a-link {
  color: #348f6c;
  text-decoration: none;

  &:visited {
    color: #348f6c;
    text-decoration: none;
  }

  &:hover {
    color: #348f6c;
    text-decoration: none;
  }

  &:active {
    color: #348f6c;
    text-decoration: none;
  }
}

.google-button {
  height: 40px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;

  &:focus,
  &:hover {
    box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.1);
  }

  &:active {
    background-color: #e5e5e5;
    box-shadow: none;
    transition-duration: 10ms;
  }
}

// verify OTP
.otp-input-container {
  margin-left: -10px;
}
.otp-input-container input {
  width: 72px !important;
  text-align: center;
  margin: 10px;
  background: #f1f3f5;
  border: 1px solid #f1f3f5;
  box-sizing: border-box;
  border-radius: 10px;
  height: 45px;
}

.otp-input-container-error input {
  border: 1px solid #cb3c3a;
  color: #cb3c3a;
}

.otp-input-container-valid input {
  border: 1px solid #1bb4a4;
}

.invalid-otp-text {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #cb3c3a;
  margin-left: 10px;
}
