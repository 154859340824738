.add-dependent {
  margin-top: 90px;
}

.add-dependent-main {
  background: #fafcfd;
  height: 80vh;
  margin-top: 20px;
  padding: 40px 20px 0 20px;

  .form-container {
    min-height: 260px;
    background-color: #ffffff;
    margin-top: 30px;
    //border: 1px solid #eaecf1;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 20px 30px 20px 30px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}

.field-box {
  width: 274px;

  .label {
    font-size: 12px;
    color: rgba(62, 74, 89, 0.5);

    .label-span {
      color: red;
    }
  }

  .text {
    color: black;
    font-size: 14px;
    margin-top: 6px;
  }

  .input-box {
    margin-top: 2px;
    width: 100%;
    background: #f8f8fc;
    border-radius: 6px;
    border: none;
    font-size: 14px;
    color: rgb(62, 74, 89);
    padding: 8px 10px;

    .input-box:focus {
      border: none;
    }
  }
}
