.emp-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.emp-container {
  background: #ffffff;
  border: 1px solid #eaecf1;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.05);
  border-radius: 8px;
  /* padding-top: 20px; */
  /* padding-right: 20px;
  padding-left: 50px; */

  padding: 15px 25px;
  display: inline-block;
  margin-bottom: 30px;
}
.label-text-container {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-right: 80px;
  min-width: 90px;
  width: 100%;
}
.emp-label-text {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  /* identical to box height, or 100% */
  text-transform: uppercase;
  color: #959fbe;
  margin-bottom: 12px;
}

.emp-bold-text {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #3d4144;
}

.emp-row-container {
  display: flex;
  flex-direction: row;
}

.emp-body-container {
  display: flex;
  flex-direction: row;
}
.emp-policy-container {
  background: rgba(234, 236, 241, 0.2);
  border-radius: 8px;
  margin-bottom: 40px;
  padding-left: 30px;
  padding-top: 30px;
  flex: 1;
  height: 320px;
  overflow-y: scroll;
}

.emp-normal-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #3d4144;
}

.text-orange {
  color: orange;
  text-transform: uppercase;
}
