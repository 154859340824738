.register-users {
  background-color: #fafcfd;
}

.upload-button {
  border: none;
  width: 431px;
  height: 48px;
  background: #348f6c;
  color: #ffffff;
  box-shadow: 0px 4px 10px rgba(52, 143, 108, 0.3);
  border-radius: 16px;
}

.valid-data-count-message {
  text-align: center;
  color: rgba(88, 150, 139, 1);
}

.input-area {
  background-color: #ffffff;
  height: auto;
  padding: 30px 120px;

  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);

  .input-area-company {
    display: flex;
    align-items: center;

    .input-area-company-title {
      color: rgba(62, 74, 89, 0.5);
      size: 16px;
    }
    .input-area-company-select {
      margin-left: 30px;
      flex-grow: 1;
    }
  }

  .input-group {
    margin-top: 25px;
  }
}
