.input-label-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #667985;
  margin-bottom: 10px;
}

.login-input.border-red {
  border: 1px solid #cb3c3a;
}

.tag-container {
  display: flex;
  flex-direction: row;
}

.tag-item-container {
  padding: 15px;
  background: #ffffff;
  border: 1px solid #1bb4a4;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 15px;

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 30px;
  cursor: pointer;
}

.tag-item-selected {
  background: #1bb4a4;
  color: white;
}
