.emp-form-container {
  display: flex;
  flex-direction: column;
  width: 55vw;
  // margin-inline: 40px;
}

.separator {
  height: 1px;
  background-color: #d4e3eb;
  margin-bottom: 40px;
}
.separator_with_employeeData {
  height: 1px;
  background-color: #d4e3eb;
  width: 100%;
  margin: 0px auto 30px auto;
}

.form-heading-title {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  /* Dark Blue */

  color: #002033;
  margin-bottom: 40px;
}

.form-coverage-title {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  /* Dark Blue */

  color: #002033;
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input-container-inside {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.inpu-box-step4 .dropdown-container {
  width: 100%;
}
.inpu-box-step4 {
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
  margin-bottom: 20px;
}
.inpu-box-step4 .form-group {
  margin-bottom: 0 !important;
}

.add-dependent-button {
  background: #ffffff;
  /* Dark Blue */

  border: 1px solid #002033;
  border-radius: 8px;
  padding: 15px 0px;
  text-align: center;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  /* Dark Blue */

  color: #002033;
  margin-bottom: 40px;
  cursor: pointer;
}

.hr-request-button {
  background: #002033;
  /* Dark Blue */

  border-radius: 8px;
  padding: 15px 0px;
  text-align: center;

  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  /* Dark Blue */

  color: white;
  margin-bottom: 40px;
  cursor: pointer;
}

.hr-request-button-disabled {
  opacity: 0.5;
}

// CSS Added to center align Add Employee Details

.mx-auto {
  width: 55%;
}

.set_new_width {
  width: 74%;
  margin: auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.react-datepicker-wrapper {
  display: block;
}

.login-input_datepicker {
  background: white;
  padding: 25px !important;
  /* Stroke */

  border: 1px solid #eaecf1 !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.07);
  border-radius: 8px !important;
  margin-bottom: 0px;
  color: #3e4a59 !important;
}
.form-text {
  margin-top: 1.2rem !important;
}

.delete-container {
  width: 50px;
  height: 50px;
  margin-top: 25px;
}

.submit-button-container {
  margin-bottom: 40px;
}
.add-emp-card {
  background: #fff;
  border: 1px solid #eaecf1;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 25px 35px;
  margin-bottom: 40px;
}
.emp-detail-back-text {
  margin-bottom: 30px !important;
}
.step-3-container .input-container .form-group:first-child {
  width: 50%;
}
.add-emp-card .input-container .form-group {
  margin-bottom: 0 !important;
}
.add-emp-card .login-input {
  padding: 20px !important;
  margin-bottom: 25px !important;
}
.add-emp-card .dropdown-container {
  padding: 8px 15px !important;
  margin-bottom: 25px !important;
  width: 100px;
}
.tag-item-container {
  padding: 10px !important;
  margin-bottom: 25px !important;
}
.dropdown-label-name {
  margin-top: 7px !important;
}
