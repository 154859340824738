.add-employee {
  margin-top: 40px;
}

.main {
  background: #fafcfd;
  height: 80vh;
  margin-top: 20px;
  padding: 40px 50px 0 120px;
}

.form-container {
  min-height: 210px;
  background-color: #ffffff;
  margin-top: 30px;
  //border: 1px solid #eaecf1;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 20px 30px 20px 30px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.field-box {
  width: 274px;

  .label {
    font-size: 12px;
    color: rgba(62, 74, 89, 0.5);

    .label-span {
      color: red;
    }
  }

  .input-box {
    margin-top: 2px;
    width: 100%;
    background: #f8f8fc;
    border-radius: 6px;
    border: none;
    font-size: 14px;
    color: rgba(62, 74, 89, 0.5);
    padding: 8px 10px;

    .input-box:focus {
      border: none;
    }
  }
}

.form-info {
  margin-top: 5px;
  font-size: 14px;
  color: rgba(62, 74, 89, 0.5);
  .span {
    color: red;
  }
}

.upload-button {
  border: none;
  width: 431px;
  height: 48px;
  background: #348f6c;
  color: #ffffff;
  box-shadow: 0px 4px 10px rgba(52, 143, 108, 0.3);
  border-radius: 16px;
}

.container {
  max-width: 1193px;
  margin: 0 auto;
}
