.upload-modal-body {
  border: 1px dashed #959fbe;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 100px;
  padding-block: 105px;
  padding-inline: 160px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-container {
  width: 60vw;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-self: center;
  flex-direction: column;
}

.upload-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  border-bottom: 1px solid #eaecf1;
}

.upload-body-container {
  border-bottom: 1px solid #eaecf1;
  overflow-y: scroll;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: space-around;
  /* padding-inline: 30px; */
}

.upload-footer {
  display: flex;
  justify-content: flex-end;
  padding-block: 10px;
  padding-right: 30px;
  align-items: center;
}

.file-container {
  background: #ffffff;
  border: 1px solid #eaecf1;
  box-sizing: border-box;
  border-radius: 8px;
  display: inline-flex;
  padding: 25px;
  margin-top: 20px;
  margin-right: 20px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-align: center;
  margin-bottom: 16px;
}

.file-text {
  color: #3d4144;
  margin-right: 20px;
  padding-right: 30px;
  font-size: 14px;
}

.header-text {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #3d4144;
}

.file-count {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #959fbe;
  display: "inline-block";
}

.error-text-count {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #cb3c3a;
}

.uploaded-more-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #348f6c;
}

.upload-policy-button {
  /* background-color: #414443; */
  /* box-shadow: 0px 4px 10px rgba(52, 143, 108, 0.3); */
  border-radius: 12px;
}

.click-upload-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: #348f6c;
  margin-left: 5px;
}

.support-pdf-text {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  color: #959fbe;
  margin-bottom: 25px;
  margin-top: 10px;
}

.choose-files-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #3d4144;
}

.spinner-container {
  width: 60vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 200;
  position: absolute;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.files-count-header {
  padding-inline: 30px;
}

.padding-inline-files {
  padding-inline: 30px;
}

.error-container {
  border-color: #cb3c3a;
}

.file-text-error {
  color: #cb3c3a;
}

.error-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 11px;
  color: #cb3c3a;
}

.file-parent {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.cirlce-container {
  width: 60vw;
  height: 100%;
  background-color: white;
  z-index: 200;
  position: absolute;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: background-color 1000ms linear;
}

.progress-text {
  color: #348f6c;
  font-size: 18px;
}

.progress-close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.verify-file-text {
  margin-right: 25px;
  display: inline-flex;
  align-items: center;
}

.verify-response-text {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #cb3c3a;
  white-space: pre-line;
  display: inline-block;
}

.summary-table-title {
  font-weight: 600;
}

.summary-table-field {
  opacity: 0.6;
  font-weight: 600;
}
