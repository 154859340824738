.view-emp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.emp-detail-back-text {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: #002033;
  cursor: pointer;
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 70px;
  cursor: pointer;
}
.emp-det-back-img {
  margin-right: 15px;
}
.view-detail-dep-list {
  display: flex;
  flex-direction: column;
}

.view-emp-inner-container {
  min-width: 500px;
}
.add_left_margin {
  margin-left: 89px;
}
