.dropdown-container {
  background: white;
  padding: 15px;
  padding-left: 25px;
  width: 100%;
  /* Stroke */
  border: 1px solid #eaecf1 !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.07);
  border-radius: 8px !important;
  margin-bottom: 40px;
  color: #3e4a59 !important;
  appearance: none;
}

.dropdown-label-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #667985;
  margin-bottom: 10px;
  margin-top: 5px;
}

.text-capitalize {
  text-transform: capitalize;
}
.text-uppercase {
  text-transform: uppercase;
}
