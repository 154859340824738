.emp-container {
  background: #ffffff;
  border: 1px solid #eaecf1;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.05);
  border-radius: 8px;
  // padding-top: 40px;
  // padding-right: 20px;
  // padding-left: 50px;
  padding: 15px 25px;
  display: inline-block;
  margin-bottom: 30px;
}

.label-text-container {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 40px;
  margin-right: 80px;
  min-width: 90px;
}

.emp-label-text {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  /* identical to box height, or 100% */
  text-transform: uppercase;
  color: #959fbe;
  margin-bottom: 12px;
}

.emp-inner-container {
  display: flex;
  flex-direction: row;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  /* identical to box height, or 100% */

  /* Section Sub Heading */

  color: #959fbe;
}

.emp-action-img {
  margin-right: 10px;
}

.emp-action-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 40px;
}
