.login-input {
  background: white;
  padding: 25px !important;
  /* Stroke */

  border: 1px solid #eaecf1 !important;
  box-sizing: border-box !important;
  box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.07);
  border-radius: 8px !important;
  margin-bottom: 40px;
  color: #3e4a59 !important;
}

.login-input.border-red {
  border: 1px solid #cb3c3a;
  margin-bottom: 20px;
}

.input-label-name {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #667985;
  margin-bottom: 10px;
}

.add-height {
  height: 100px;
}
