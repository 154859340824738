.logo {
  /* color: rgb(23, 162, 184); */
  color: rgb(255, 255, 255);
  height: 40px;
  /* font-size: 2.5rem; */

  /* text-decoration: none; */
}

.logo:hover {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
}

.nav-container {
  padding: 10px 40px;
  background: #f7f8fa;
  border-bottom: 1px solid #ccc;
}

.nav {
  color: #0e4da4;
}
.logout {
  margin-bottom: 5px;
  margin-top: 10px;
  color: #348f6c;
  cursor: pointer;
}
