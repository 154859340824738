.dependent-container {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}

.view-parent-container {
  display: flex;
  justify-content: center;
}

.view-detaill-emp-container {
  display: flex;
  flex-direction: column;
}

.view-emp-header {
  margin: 30px 0px;
  cursor: pointer;
  display: flex;
}

.arrow-left-emp-img {
  width: 20px;
  transform: rotateZ(180deg);
}

.dependent-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.view-emp-add-dep-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hover {
  cursor: pointer;
}

.heading-bold-text {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  color: #3d4144;
}

.add-dependent-text {
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  color: #348f6c;
}
/* .mx-auto {
  width: 75%;
} */
