.upload-body-container-success {
  border-bottom: 1px solid #eaecf1;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: space-around;
  /* padding-inline: 30px; */
}

.upload-container-success {
  width: 60vw;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-self: center;
  flex-direction: column;
}
